import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    reactiveForm: FormGroup;
    nameinvalidError: boolean;
    emailinvalid: boolean;
    phoneinvalid: boolean;
    messageinvalid: boolean;

  constructor(private http: HttpClient,
    private fb: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.reactiveForm = this.fb.group({
        name:['',Validators.required],
        email:['',Validators.required],
        phone:['',Validators.required],
        subject:[''],
        message:['',Validators.required]
    });
  }

  nameinvalid() {
    if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
            this.nameinvalidError = true;
        } else {
            this.nameinvalidError = false;
        }
    }

    emailinvalidError() {
        if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
            this.emailinvalid = true;
        } else {
            this.emailinvalid = false;
        }
    }

    phoneinvalidError() {
        if(this.reactiveForm.get('phone').value == "" || this.reactiveForm.get('phone').value == undefined){
            this.phoneinvalid = true;
        } else {
            this.phoneinvalid = false;
        }
    }

    messageinvalidError() {
        if(this.reactiveForm.get('message').value == "" || this.reactiveForm.get('message').value == undefined){
            this.messageinvalid = true;
        } else {
            this.messageinvalid = false;
        }
    }

    submitData(){
        console.log(this.reactiveForm.value);
        if(this.reactiveForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
            this.messageinvalidError();
            this.phoneinvalidError();
        } else {
          const contactusdata = this.http.post('https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/contactus/dynamodb',this.reactiveForm.value)
          const emailVerification = this.http.post('https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/contactus/email',this.reactiveForm.value)

          forkJoin([contactusdata,emailVerification]).subscribe(res =>{
              return res;
          })
          this.reactiveForm.reset();
        }
    }

}
