import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router, RouterModule, Routes} from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

    searchText: string;
    list: any;
    // list:any [];
    pageOfItems: Array<any>;
    q:any;


  constructor(private http: HttpClient,
    private router: Router) { }

  ngOnInit(): void {
    this.http.get('https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/blog').subscribe(res =>{
    const data = res;
    this.list = data;
    this.list =this.list.content

    })
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

  getData(m) {
    this.router.navigate(['/blog-details'],{ queryParams: { id: m } });
  }

}
