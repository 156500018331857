<div class="page-title-area">
   <div class="container">
      <div class="page-title-content">
         <h2>Terms & Conditions</h2>
         <ul>
            <li><a routerLink="/">Home </a></li>
            <li class="active">Terms & Conditions</li>
         </ul>
      </div>
   </div>

   <div class="page-shape">
      <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
   </div>
</div>

<section class="privecy-area ptb-100">
   <div class="container">
      <div>
         <h6>Introduction</h6>
         <p> These terms and conditions apply between you, the User of this Website (including any sub-domains, unless
            expressly excluded by their own terms and conditions), and RAD SUN IT SOLUTIONS PRIVATE LIMITED, the owner
            and operator of this Website. Please read these terms and conditions carefully, as they affect your legal
            rights. Your agreement to comply with and be bound by these terms and conditions is deemed to occur upon
            your first use of the Website. If you do not agree to be bound by these terms and conditions, you should
            stop using the Website immediately. </p>
         <p> In these terms and conditions, User or Users means any third party that accesses the Website and is not
            either (i) employed by RAD SUN IT SOLUTIONS PRIVATE LIMITED and acting in the course of their employment or
            (ii) engaged as a consultant or otherwise providing services to RAD SUN IT SOLUTIONS PRIVATE LIMITED and
            accessing the Website in connection with the provision of such services. </p>
         <p> You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms
            and conditions, you represent and warrant that you are at least 18 years of age. </p>
         <h6>Intellectual property and acceptable use</h6>
         <p> 1. All Content included on the Website, unless uploaded by Users, is the property of RAD SUN IT SOLUTIONS
            PRIVATE LIMITED, our affiliates or other relevant third parties. In these terms and conditions, Content
            means any text, graphics, images, audio, video, software, data compilations, page layout, underlying code
            and software and any other form of information capable of being stored in a computer that appears on or
            forms part of this Website, including any such content uploaded by Users. By continuing to use the Website
            you acknowledge that such Content is protected by copyright, trademarks, database rights and other
            intellectual property rights. Nothing on this site shall be construed as granting, by implication, estoppel,
            or otherwise, any license or right to use any trademark, logo or service mark displayed on the site without
            the owner's prior written permission
            <br> 2. You may, for your own personal, non-commercial use only, do the following: retrieve, display and
            view the Content on a computer screen
            <br> 3. You must not otherwise reproduce, modify, copy, distribute or use for commercial purposes any
            Content without the written permission of RAD SUN IT SOLUTIONS PRIVATE LIMITED.
         </p>
         <h6>Prohibited use</h6>
         <p> 4. You may not use the Website for any of the following purposes:
            <br> a. in any way which causes, or may cause, damage to the Website or interferes with any other person's
            use or enjoyment of the Website;
            <br> b. in any way which is harmful, unlawful, illegal, abusive, harassing, threatening or otherwise
            objectionable or in breach of any applicable law, regulation, governmental order;<br>
            c. making, transmitting or storing electronic copies of Content protected by copyright without the
            permission of the owner.
         </p>
         <h6>Links to other websites</h6>
         <p> 5. This Website may contain links to other sites. Unless expressly stated, these sites are not under the
            control of RAD SUN IT SOLUTIONS PRIVATE LIMITED or that of our affiliates.<br> 6. We assume no
            responsibility for the content of such Websites and disclaim liability for any and all forms of loss or
            damage arising out of the use of them.<br> 7. The inclusion of a link to another site on this Website does
            not imply any endorsement of the sites themselves or of those in control of them. </p>
         <h6>Privacy Policy and Cookies Policy</h6>
         <p> 8. Use of the Website is also governed by our Privacy Policy and Cookies Policy, which are incorporated
            into these terms and conditions by this reference. To view the Privacy Policy and Cookies Policy, please
            click on the following: <a routerlink="/privacy-policy"
               href="/privacy-policy">https://www.radsunitsolutions.com/privacy-policy,</a> and <a
               href="/cookie-policy">https://www.radsunitsolutions.com/cookie-policy.</a></p>
         <h6>Availability of the Website and disclaimers</h6>
         <p> 9. Any online facilities, tools, services or information that RAD SUN IT SOLUTIONS PRIVATE LIMITED makes
            available through the Website (the Service) is provided "as is" and on an "as available" basis. We give no
            warranty that the Service will be free of defects and/or faults. To the maximum extent permitted by the law,
            we provide no warranties (express or implied) of fitness for a particular purpose, accuracy of information,
            compatibility and satisfactory quality. RAD SUN IT SOLUTIONS PRIVATE LIMITED is under no obligation to
            update information on the Website.<br>
            10. Whilst RAD SUN IT SOLUTIONS PRIVATE LIMITED uses reasonable endeavours to ensure that the Website is
            secure and free of errors, viruses and other malware, we give no warranty or guaranty in that regard and all
            Users take responsibility for their own security, that of their personal details and their computers.<br>
            11. RAD SUN IT SOLUTIONS PRIVATE LIMITED accepts no liability for any disruption or non-availability of the
            Website.
            <br> 12. RAD SUN IT SOLUTIONS PRIVATE LIMITED reserves the right to alter, suspend or discontinue any part
            (or the whole of) the Website including, but not limited to, any products and/or services available. These
            terms and conditions shall continue to apply to any modified version of the Website unless it is expressly
            stated otherwise.
         </p>
         <h6>Limitation of liability</h6>
         <p> 13. Nothing in these terms and conditions will: (a) limit or exclude our or your liability for death or
            personal injury resulting from our or your negligence, as applicable; (b) limit or exclude our or your
            liability for fraud or fraudulent misrepresentation; or (c) limit or exclude any of our or your liabilities
            in any way that is not permitted under applicable law.<br>
            14. We will not be liable to you in respect of any losses arising out of events beyond our reasonable
            control.
            <br> 15. To the maximum extent permitted by law, RAD SUN IT SOLUTIONS PRIVATE LIMITED accepts no liability
            for any of the following:
            <br> a. any business losses, such as loss of profits, income, revenue, anticipated savings, business,
            contracts, goodwill or commercial opportunities;
            <br> b. loss or corruption of any data, database or software;
            <br> c. any special, indirect or consequential loss or damage.
         </p>
         <h6>General</h6>
         <p> 16. You may not transfer any of your rights under these terms and conditions to any other person. We may
            transfer our rights under these terms and conditions where we reasonably believe your rights will not be
            affected.
            <br> 17. These terms and conditions may be varied by us from time to time. Such revised terms will apply to
            the Website from the date of publication. Users should check the terms and conditions regularly to ensure
            familiarity with the then current version.
            <br> 18. These terms and conditions together with the Privacy Policy and Cookies Policy contain the whole
            agreement between the parties relating to its subject matter and supersede all prior discussions,
            arrangements or agreements that might have taken place in relation to the terms and conditions
            .<br> 19. The Contracts (Rights of Third Parties) Act 1999 shall not apply to these terms and conditions and
            no third party will have any right to enforce or rely on any provision of these terms and conditions.
            <br> 20. If any court or competent authority finds that any provision of these terms and conditions (or part
            of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent
            required, be deemed to be deleted, and the validity and enforceability of the other provisions of these
            terms and conditions will not be affected.
            <br> 21. Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy
            will be deemed a waiver of that, or any other, right or remedy.<br> 22. This Agreement shall be governed by
            and interpreted according to the law of and all disputes arising under the Agreement
            (including non-contractual disputes or claims) shall be subject to the exclusive jurisdiction of the English
            and Welsh courts.
         </p>
         <h6>RAD SUN IT SOLUTIONS PRIVATE LIMITED details</h6>
         <p> 23. RAD SUN IT SOLUTIONS PRIVATE LIMITED whose registered address is 26, Nowroji Street, Chetpet, Chennai, Tamilnadu, 600031 and it
            operates the Website <a href="https://www.radsunitsolutions.com/">www.radsunitsolutions.com.</a><br> You can
            contact RAD SUN IT SOLUTIONS PRIVATE LIMITED by email on <a
               href="mailto:contact@radsunitsolutions.com">contact@radsunitsolutions.com</a>
         </p>
      </div>
   </div>
</section>