import { Component, OnInit } from '@angular/core';
import { Router, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent {
    // cookie banner
    // https://developers.de/2018/10/29/implementing-cookie-for-angular-app/
    location: any;
    routerSubscription: any;
    public lat;
    public lng;

    cookieMessage: any ='This website uses cookies and similar technologies to ensure you get the best experience on our website.We also store cookies to personalize the website content and to serve more relevant content to you.By Continuing to use the service, It will assume that you are agreeing to our privacy policy.';
     cookieDismiss: any = 'Agree';
    cookieLinkText: any = 'Learn More';

    constructor(private router: Router) {
    }

    ngOnInit(){
        let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#164969"
           },
           button: {
             background: "#ffe000",
             text: "#164969"
           }
         },
         theme: "classic",
         content: {
           message: this.cookieMessage,
           dismiss: this.cookieDismiss,
           link: this.cookieLinkText,
           href: "/cookie-policy"
         }
       });

       this.recallJsFuntions();

    }

    recallJsFuntions() {
        this.routerSubscription = this.router.events
        .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
        .subscribe(event => {
            this.location = this.router.url;
            if (!(event instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    getUserLocation() {
        if (navigator.geolocation) {
         navigator.geolocation.getCurrentPosition(position => {
             this.lat = position.coords.latitude;
             this.lng = position.coords.longitude;
           });
        }
     else {
        console.log("User not allow") }
     }
}
