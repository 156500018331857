<div class="page-title-area">
   <div class="container">
      <div class="page-title-content">
         <h2>Privacy & Policy</h2>
         <ul>
            <li><a routerLink="/">Home </a></li>
            <li class="active">Privacy & Policy</li>
         </ul>
      </div>
   </div>

   <div class="page-shape">
      <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
   </div>
</div>

<section class="privecy-area ptb-100">
   <div class="container">
      <div class="col-lg-12 col-md-12">
         <p> This privacy policy applies between you, the User of this Website, and RAD SUN IT SOLUTIONS PRIVATE
            LIMITED, the owner and provider of this Website. RAD SUN IT SOLUTIONS PRIVATE LIMITED takes the privacy of
            your information very seriously. This privacy policy applies to our use of any and all Data collected by us
            or provided by you in relation to your use of the Website. </p>
         <p> This privacy policy should be read alongside, and in addition to, our Terms and Conditions, which can be
            found at: <a routerlink="/terms-condition" href="/terms-condition">
               https://www.radsunitsolutions.com/terms-condition</a></p>
         <p><b>Please read this privacy policy carefully.</b></p>
         <h6>Definitions and interpretation</h6>
         <p> 1. In this privacy policy, the following definitions are used: </p>
         <div class="order-table table-responsive">
            <table class="table table-bordered">
               <tbody>
                  <tr>
                     <th scope="row"> Data </th>
                     <td> collectively all information that you submit to RAD SUN IT SOLUTIONS PRIVATE LIMITED via the
                        Website. This definition incorporates, where applicable, the definitions provided in the Data
                        Protection Laws; </td>
                  </tr>
                  <tr>
                     <th scope="row"> Cookies </th>
                     <td> a small text file placed on your computer by this Website when you visit certain parts of the
                        Website and/or when you use certain features of the Website. Details of the cookies used by this
                        Website are set out in the clause below (Cookies); </td>
                  </tr>
                  <tr>
                     <th scope="row"> Data Protection Laws </th>
                     <td> any applicable law relating to the processing of personal Data, including but not limited to
                        the GDPR, and any national implementing and supplementary laws, regulations and secondary
                        legislation; </td>
                  </tr>
                  <tr>
                     <th scope="row"> RAD SUN IT SOLUTIONS PRIVATE LIMITED, we or us </th>
                     <td> RAD SUN IT SOLUTIONS PRIVATE LIMITED, a company incorporated in 
                        registered number RAD SUN IT SOLUTIONS PRIVATE LIMITED whose registered office is at 26, Nowroji
                        Street, Chetpet, Chennai, Tamilnadu, 600031; </td>
                  </tr>
                  <tr>
                     <th scope="row"> User or you </th>
                     <td> any third party that accesses the Website and is not either (i) employed by RAD SUN IT
                        SOLUTIONS PRIVATE LIMITED and acting in the course of their employment or (ii) engaged as a
                        consultant or otherwise providing services to RAD SUN IT SOLUTIONS PRIVATE LIMITED and accessing
                        the Website in connection with the provision of such services; and </td>
                  </tr>
                  <tr>
                     <th scope="row"> Website </th>
                     <td> the website that you are currently using, <a
                           href="https://www.radsunitsolutions.com/">https://www.radsunitsolutions.com/</a>, and any
                        sub-domains of this site unless expressly excluded by their own terms and conditions. </td>
                  </tr>
               </tbody>
            </table>
            <p> 2. In this privacy policy, unless the context requires a different interpretation: <br> &nbsp;a. the
               singular includes the plural and vice versa; <br> &nbsp;b. references to sub-clauses, clauses, schedules
               or appendices are to sub-clauses, clauses, schedules or appendices of this privacy policy; <br> &nbsp;c.
               a reference to a person includes firms, companies, government entities, trusts and partnerships; <br>
               &nbsp;d. "including" is understood to mean "including without limitation"; <br> &nbsp;e. reference to any
               statutory provision includes any modification or amendment of it; <br> &nbsp;f. the headings and
               sub-headings do not form part of this privacy policy. <br></p>
            <h6> Scope of this privacy policy</h6>
            <p> 3. This privacy policy applies only to the actions of RAD SUN IT SOLUTIONS PRIVATE LIMITED and Users
               with respect to this Website. It does not extend to any websites that can be accessed from this Website
               including, but not limited to, any links we may provide to social media websites.<br> 4. For purposes of
               the applicable Data Protection Laws, RAD SUN IT SOLUTIONS PRIVATE LIMITED is the "data controller". This
               means that RAD SUN IT SOLUTIONS PRIVATE LIMITED determines the purposes for which, and the manner in
               which, your Data is processed. </p>
            <h6>Data collected</h6>
            <p> 5. We may collect the following Data, which includes personal Data, from you:<br>
               &nbsp;a. name;<br>
               &nbsp;b. date of birth;<br>
               &nbsp;c. gender;<br>
               &nbsp;d. job title;<br>
               &nbsp;e. profession;<br>
               &nbsp;f. contact Information such as email addresses and telephone numbers;<br>
               &nbsp;g. demographic information such as postcode, preferences and interests;<br>
               &nbsp;h. IP address (automatically collected);<br>
               &nbsp;i. web browser type and version (automatically collected);<br>
               &nbsp;j. operating system (automatically collected);<br>
               &nbsp;k. a list of URLs starting with a referring site, your activity on this Website, and the site you
               exit to (automatically collected);<br>
               &nbsp;l. Also details shown in Contact us page on our website <a routerlink="/contact"
                  href="/contact">https://www.radsunitsolutions.com/contact;</a><br>
               &nbsp;in each case, in accordance with this privacy policy.<br>
            </p>
            <h6>How we collect Data</h6>
            <p> 6. We collect Data in the following ways:<br>
               &nbsp;a. data is given to us by you;<br>
               &nbsp;b. data is received from other sources; and<br>
               &nbsp;c. data is collected automatically.<br>
            </p>
            <h6>Data that is given to us by you</h6>
            <p>
               7. RAD SUN IT SOLUTIONS PRIVATE LIMITED will collect your Data in a number of ways, for example:<br>
               &nbsp;a. when you contact us through the Website, by telephone, post, e-mail or through any other
               means;<br>
               &nbsp;b. when you register with us and set up an account to receive our products/services;<br>
               &nbsp;c. when you complete surveys that we use for research purposes (although you are not obliged to
               respond to them);<br>
               &nbsp;d. when you enter a competition or promotion through a social media channel;<br>
               &nbsp;e. when you make payments to us, through this Website or otherwise;<br>
               &nbsp;f. when you elect to receive marketing communications from us;<br>
               &nbsp;g. when you use our services;<br>
               &nbsp;h. in each case, in accordance with this privacy policy.
            </p>
            <h6>Data that is received from third parties</h6>
            <p>8. RAD SUN IT SOLUTIONS PRIVATE LIMITED will receive Data about you from the publicly available third
               parties;</p>
            <h6>Data that is received from publicly available third parties sources</h6>
            <p>9. We will receive Data about you from the publicly available third party sources;</p>
            <h6>Data that is collected automatically</h6>
            <p> 10. To the extent that you access the Website, we will collect your Data automatically, for example:<br>
               &nbsp;a. we automatically collect some information about your visit to the Website. This information
               helps us to make improvements to Website content and navigation, and includes your IP address, the date,
               times and frequency with which you access the Website and the way you use and interact with its
               content.<br>
               &nbsp;b. we will collect your Data automatically via cookies, in line with the cookie settings on your
               browser. For more information about cookies, and how we use them on the Website, see the section below,
               headed "Cookies".
               <br>
            </p>
            <h6>Our use of Data</h6>
            <p> 11. Any or all of the above Data may be required by us from time to time in order to provide you with
               the best possible service and experience when using our Website. Specifically, Data may be used by us for
               the following reasons:<br>
               &nbsp;a. internal record keeping;<br>
               &nbsp;b. improvement of our products / services;<br>
               &nbsp;c. transmission by email of marketing materials that may be of interest to you;<br>
               &nbsp;d. contact for market research purposes which may be done using email, telephone, fax or mail. Such
               information may be used to customise or update the Website;<br>
               &nbsp;e. in each case, in accordance with this privacy policy.<br>
               12. We may use your Data for the above purposes if we deem it necessary to do so for our legitimate
               interests. If you are not satisfied with this, you have the right to object in certain circumstances (see
               the section headed "Your rights" below).<br>
               13. For the delivery of direct marketing to you via e-mail, we'll need your consent, whether via an
               opt-in or soft-opt-in:<br>
               &nbsp;a. soft opt-in consent is a specific type of consent which applies when you have previously engaged
               with us (for example, you contact us to ask us for more details about a particular product/service, and
               we are marketing similar products/services). Under "soft opt-in" consent, we will take your consent as
               given unless you opt-out.<br>
               &nbsp;b. for other types of e-marketing, we are required to obtain your explicit consent; that is, you
               need to take positive and affirmative action when consenting by, for example, checking a tick box that
               we'll provide.<br>
               &nbsp;c. if you are not satisfied with our approach to marketing, you have the right to withdraw consent
               at any time. To find out how to withdraw your consent, see the section headed "Your rights" below.<br>
               14. When you register with us and set up an account to receive our services, the legal basis for this
               processing is the performance of a contract between you and us and/or taking steps, at your request, to
               enter into such a contract.<br>
               15. We may use your Data to show you RAD SUN IT SOLUTIONS PRIVATE LIMITED adverts and other content on
               other websites. If you do not want us to use your data to show you RAD SUN IT SOLUTIONS PRIVATE LIMITED
               adverts and other content on other websites, please turn off the relevant cookies (please refer to the
               section headed "Cookies" below).<br>
            </p>
            <h6>Who we share Data with</h6>
            <p> 16. We may share your Data with the following groups of people for the following reasons:<br> &nbsp;a.
               our employees, agents and/or professional advisors - ;in each case, in accordance with this privacy
               policy.</p>
            <h6>Keeping Data secure</h6>
            <p>
               17. We will use technical and organisational measures to safeguard your Data, for example:<br>
               &nbsp;a. access to your account is controlled by a password and a user name that is unique to you.<br>
               &nbsp;b. we store your Data on secure servers.<br>
               18. We are certified to ISO 27001. This family of standards helps us manage your Data and keep it
               secure.<br>
               19. Technical and organisational measures include measures to deal with any suspected data breach. If you
               suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by
               contacting us via this e-mail address: <a
                  href="mailto:contact@radsunitsolutions.com">contact@radsunitsolutions.com.</a><br>
               20. If you want detailed information from Get Safe Online on how to protect your information and your
               computers and devices against fraud, identity theft, viruses and many other online problems, please visit
               <a href="https://www.getsafeonline.org/">www.getsafeonline.org</a>. Get Safe Online is supported by HM
               Government and leading businesses.
            </p>
            <h6>Data retention</h6>
            <p>
               21. Unless a longer retention period is required or permitted by law, we will only hold your Data on our
               systems for the period necessary to fulfil the purposes outlined in this privacy policy or until you
               request that the Data be deleted.<br>
               22. Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory
               purposes.
            </p>
            <h6>Your rights</h6>
            <p> 23. You have the following rights in relation to your Data:<br> &nbsp;a. Right to access - the right to
               request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or
               delete such information. If we provide you with access to the information we hold about you, we will not
               charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally
               permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons
               why.<br> &nbsp;b. Right to correct - the right to have your Data rectified if it is inaccurate or
               incomplete.<br> &nbsp;c. Right to erase - the right to request that we delete or remove your Data from
               our systems.<br> &nbsp;d. Right to restrict our use of your Data - the right to "block" us from using
               your Data or limit the way in which we can use it.<br> &nbsp;e. Right to data portability - the right to
               request that we move, copy or transfer your Data.<br> &nbsp;f. Right to object - the right to object to
               our use of your Data including where we use it for our legitimate interests.<br> 24. To make enquiries,
               exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where
               consent is our legal basis for processing your Data), please contact us via this e-mail address: <a
                  href="mailto:contact@radsunitsolutions.com">contact@radsunitsolutions.com.</a><br> 25. If you are not
               satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to
               refer your complaint to the relevant data protection authority. The Information Commissioner's Office
               (ICO). The ICO's contact details can be found on their website<br> 26. It is important that the Data we
               hold about you is accurate and current. Please keep us informed if your Data changes during the period
               for which we hold it.</p>
            <h6>Links to other websites</h6>
            <p>
               27. This Website may, from time to time, provide links to other websites. We have no control over such
               websites and are not responsible for the content of these websites. This privacy policy does not extend
               to your use of such websites. You are advised to read the privacy policy or statement of other websites
               prior to using them.
            </p>
            <h6>Changes of business ownership and control</h6>
            <p> 28. RAD SUN IT SOLUTIONS PRIVATE LIMITED may, from time to time, expand or reduce our business and this
               may involve the sale and/or the transfer of control of all or part of RAD SUN IT SOLUTIONS PRIVATE
               LIMITED. Data provided by Users will, where it is relevant to any part of our business so transferred, be
               transferred along with that part and the new owner or newly controlling party will, under the terms of
               this privacy policy, be permitted to use the Data for the purposes for which it was originally supplied
               to us.<br> 29. We may also disclose Data to a prospective purchaser of our business or any part of
               it.<br> 30. In the above instances, we will take steps with the aim of ensuring your privacy is
               protected.<br></p>
            <h6>Cookies</h6>
            <p> 31. This Website may place and access certain Cookies on your computer. RAD SUN IT SOLUTIONS PRIVATE
               LIMITED uses Cookies to improve your experience of using the Website and to improve our range of products
               and services. RAD SUN IT SOLUTIONS PRIVATE LIMITED has carefully chosen these Cookies and has taken steps
               to ensure that your privacy is protected and respected at all times.<br> 32. All Cookies used by this
               Website are used in accordance.<br> 33. Before the Website places Cookies on your computer, you will be
               presented with a message bar requesting your consent to set those Cookies. By giving your consent to the
               placing of Cookies, you are enabling RAD SUN IT SOLUTIONS PRIVATE LIMITED to provide a better experience
               and service to you. You may, if you wish, deny consent to the placing of Cookies; however certain
               features of the Website may not function fully or as intended.<br> 34. This Website may place the
               following Cookies: Refer our <a routerlink="/cookie-policy" href="/cookie-policy"> Cookie Policy </a> for
               more details. 35. You can find a list of Cookies that we use in the Cookies Schedule.<br>
               36. You can choose to enable or disable Cookies in your internet browser. By default, most internet
               browsers accept Cookies but this can be changed. For further details, please see the help menu in your
               internet browser. You can switch off Cookies at any time, however, you may lose any information that
               enables you to access the Website more quickly and efficiently.<br>
               37. You can choose to delete Cookies at any time; however, you may lose any information that enables you
               to access the Website more quickly and efficiently including, but not limited to, personalisation
               settings.<br>
               38. It is recommended that you ensure that your internet browser is up-to-date and that you consult the
               help and guidance provided by the developer of your internet browser if you are unsure about adjusting
               your privacy settings.<br>
               39. For more information generally on cookies, including how to disable them, please refer to
               aboutcookies.org. You will also find details on how to delete cookies from your computer.
            </p>
            <h6>General</h6>
            <p> 40. You may not transfer any of your rights under this privacy policy to any other person. We may
               transfer our rights under this privacy policy where we reasonably believe your rights will not be
               affected.<br> 41. If any court or competent authority finds that any provision of this privacy policy (or
               part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to
               the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions
               of this privacy policy will not be affected.<br> 42. Unless otherwise agreed, no delay, act or omission
               by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or
               remedy.<br> 43. This Agreement will be governed by and interpreted according to the law. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the
               English and Welsh courts.<br></p>
            <h6>Changes to this privacy policy</h6>
            <p> 44. RAD SUN IT SOLUTIONS PRIVATE LIMITED reserves the right to change this privacy policy as we may deem
               necessary from time to time or as may be required by law. Any changes will be immediately posted on the
               Website and you are deemed to have accepted the terms of the privacy policy on your first use of the
               Website following the alterations.<br> 45. You may contact RAD SUN IT SOLUTIONS PRIVATE LIMITED by email
               at <a href="mailto:contact@radsunitsolutions.com">contact@radsunitsolutions.com.</a></p>
         </div>
      </div>
   </div>
</section>