<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Dedicated Development Crew Solutions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Dedicated Development Crew Solutions</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="services-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="services-img mb">
                    <img src="assets/images/solutions-details/1.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6">
                <div class="services-img mb">
                    <img src="assets/images/solutions-details/2.png" alt="Image">
                </div>
            </div>

            <div class="col-12">
                <div class="services-details-text">
                    <h2>Dedicated Development Team Hiring Service</h2>
                    <p>We provide our clients with a long-term solution through the use of our Dedicated Development Team Hiring service.</p>
                    <p>Team made up of the most important persons involved in the project's SDLC,which is commonly followed by Agile Scrum.</p>
                    <p>This team of specialists is assembled to meet the unique business objectives of your firm, and it is structured in a manner that is similar to your in-house teams. In essence,this team become an extension of your company during the development phase.</p>
                    <p>Team members include: a project manager, a business analyst, a webdesigner, a frontend developer, a back end developer, a development operations team, and a quality assurance team.</p>

                </div>
            </div>
        </div>

        <div class="scrives-item-2 mt-4 ">
            <div class="row align-items-center">
                <div class="col-lg-4">
                    <div class="services-img mb-qc">
                        <img src="assets/images/solutions-details/5.png" alt="Image">
                    </div>
                </div>

                <div class="col-lg-8">
                    <h3>IT Staff Augmentation Service</h3>
                    <p>Our IT Staff Augmentation service is designed to supplement your existing team with additional talent on an as-needed basis. This enables firms to find the best fit for difficult-to-fill or temporary positions almost instantly, increasing the scalability and efficiency of project development</p>
                </div>
            </div>
        </div>
    </div>
</section>
