<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our Team</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Team</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="team-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our Software Project Team Structure</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/14.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Tech CEO and Founder</p>
                        <p>Business > Management Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/1.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Operations Analyst</p>
                        <p>Business > Client Services Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/2.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>API & Cloud Integration Architect Dev</p>
                        <p>Technology > Architects > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/3.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Tech Project Manager</p>
                        <p>Technology > PMO > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/4.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Principle Data Architect Dev</p>
                        <p>Technology > Architects > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/5.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Front End Architect Dev</p>
                        <p>Technology > Architects > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/6.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Cloud Dev Ops Engineer</p>
                        <p>Technology > Dev Ops > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/7.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Research Analyst</p>
                        <p>R & D Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/8.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Tech Business Analyst</p>
                        <p>Technology > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/9.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Tech Content Writer</p>
                        <p>R & D Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/10.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>AI / ML Specialist</p>
                        <p>Technology > Back-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/11.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Web Designer</p>
                        <p>Technology > Front-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/12.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Front End Web SPA Expert</p>
                        <p>Technology > Front-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/13.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Front End Mobile App Expert</p>
                        <p>Technology > Front-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/18.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Node.JS Expert</p>
                        <p>Technology > Back-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/15.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Python Ninja</p>
                        <p>Technology > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/16.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Full Stack Engineer</p>
                        <p>Technology > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/17.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Lead Data Analyst</p>
                        <p>Technology > Back-End > Dev Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/19.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>QA</p>
                        <p>Technology > QA > Testing Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-team">
                    <div class="team-img">
                        <img src="assets/images/team/20.png" alt="team">
                    </div>

                    <div class="team-content">
                        <h3></h3>
                        <p>Full Stack Engineer</p>
                        <p>Technology > Support & Maintenance Team</p>
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            <li><a href="#" target="_blank"><i class="bx bxl-google-plus"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
