<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Tech Stack Expertise</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Tech Stack Expertise</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="tech-stack-expertise-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Technologies & Expertise</h2>
            <p>Our technical experts at RAD SUN IT SOLUTIONS PRIVATE LIMITED assist businesses in completing their digital transformation journey and in delivering stable, scalable apps of any level of complexity to their customers with ease. Depending on the project requirements, we employ the following widely used market technologies.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/1.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/2.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/3.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/4.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/5.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/6.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="softwares">
                    <div class="team-img">
                        <img src="assets/images/technologies/7.png" alt="tech-stack-expertise">
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>

