<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Sign In</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Sign In</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="signup-area ptb-100">
    <div class="container">
        <div class="signup-form">
            <div class="section-tittle text-center">
                <h2>Sign In</h2>
                <p>With your social network</p>
            </div>

            <form>
                <div class="row">
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <a class="box-btn google" href="#" target="_blank"><i class='bx bxl-google'></i> Google</a>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <a class="box-btn facebook" href="#" target="_blank"><i class='bx bxl-facebook'></i> Facebook</a>
                    </div>

                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <a class="box-btn twitter" href="#" target="_blank"><i class='bx bxl-twitter'></i> Twitter</a>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="name" placeholder="User Name">
                        </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" type="email" name="email" placeholder="Email">
                        </div>
                    </div>

                    <div class="col-md-12 col-sm-12">
                        <div class="form-group">
                            <input class="form-control" type="text" name="password" placeholder="Password">
                        </div>
                    </div>

                    <div class="col-md-12 col-sm-12 col-xs-12">
                        <div class="privecy-txt">
                            <input type="checkbox" id="chb1">
                            <label>Remember me</label>
                        </div>
                    </div>

                    <div class="col-12 text-center">
                        <button type="submit" class="box-btn signup-btn">Sign In</button>
                    </div>

                    <div class="col-12 text-center">
                        <p class="al-acc">Not a Member <a routerLink="/sign-up"> Sign Up</a></p>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
