<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <!-- <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div> -->
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Why RAD SUN IT SOLUTIONS PRIVATE LIMITED?</h2>
                        <p>RAD SUN IT SOLUTIONS PRIVATE LIMITED is an enhancing operational firm that specialises in technology solutions. </p>
                        <p>We collaborate with small and medium-sized enterprises, using cutting-edge technology to guide them through all stages of the software development cycle.</p>
                        <p>We create customized web/mobile applications to solve business problems, promote development, and help you reach your company objectives.</p>
                        <a href="/all-services">Please see our All Services page.</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/1.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-case pt-10 pb-70">
    <div class="container">
        <div class="case">
            <div class="row case-list">
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a routerLink="/case-studies-details"><h3>Our Team</h3></a>
                            <p>To provide the best solutions to our clients, we have assembled a team of skilled and certified professionals.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a routerLink="/case-studies-details"><h3>Our Objective</h3></a>
                            <p>By designing and transforming the way technology is used, we hope to provide a more engaging experience for our customers.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a routerLink="/case-studies-details"><h3>Who we are</h3></a>
                            <p>We are a consultancy firm based in the India that provides technology solutions.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
