<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Jobs</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Jobs</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="home-service-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Jobs List</h2> </div>
            <div class="search_tab">
                <div class="form-outline mb-4">
                    <input type="search" [(ngModel)]="searchText" class="form-control" id="datatable-search-input" placeholder="Search by job title or keyword">
                </div>
            </div>
        <div class="row">
            <ul class="list-group" *ngFor="let item of listData | searchFilter: searchText | paginate: { itemsPerPage: 4,currentPage: q } ">
            <li class="list-group-item list-group-item-action d-flex gap-3 py-3 mb-2 mt-2" aria-current="true">
               <div class="d-flex gap-2 w-100 justify-content-between">
                  <div>
                     <h3 class="mb-0">{{item.jobTitle}}</h3>
                     <span  [ngClass]="item.status == 'Closed' ? 'badge badge-pill badge-danger' : 'badge badge-pill badge-info'">{{item.status}}</span>
                     <p>{{item.jobDescription}}</p>
                  </div>
               </div>
            </li>
         </ul>
         <pagination-controls (pageChange)="q = $event" class="pagine"></pagination-controls>
        </div>
    </div>
</section>
