<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>FAQ</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">FAQ</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <!-- <span>Choose Aiva</span> -->
            <h2>We Achieved People’s Trust by Our Great Service</h2></div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-group [isOpened]="true" heading="Can you review an existing website and identify issues such as slow performance and review the technology stack?">
                                <p>Yes, we can do this as part of Code Review Service.</p>
                            </accordion-group>
                            <accordion-group heading="I'm not sure what my product's MVP is. Where do I begin?">
                                <p>At RAD SUN IT SOLUTIONS PRIVATE LIMITED, our team of experts begins by comprehending your product and conducting extensive market research analysis. Following that, they will describe to you the key features of the product that provide significant business value. This, in turn, paves the way for determining the requirements for the minimum version of the working product.</p>
                            </accordion-group>
                            <accordion-group heading="When should I move on to a clickable prototype?">
                                <p>A clickable prototype will be created once your product idea has been validated through market research, an MVP has been identified and web designs have been approved.</p>
                            </accordion-group>
                            <accordion-group heading="Do you create back-office systems to support a website or a mobile app?">
                                <p>Yes.</p>
                            </accordion-group>
                            <accordion-group heading="Do you migrate legacy applications?">
                                <p>Yes.</p>
                            </accordion-group>
                            <accordion-group heading="Can you create stand-alone modules such as UI Components or APIs that can be used in existing systems?">
                                <p>Yes.</p>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>Let Us Know About Your Project Idea!</h2>
            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
        </div>

        <div class="content">
            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" required placeholder="Your Name" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" required placeholder="Your Email" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Your Phone" />
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Subject" />
                        </div>
                    </div>

                    <div class="col-lg-12">
                        <div class="form-group">
                            <textarea name="message" class="form-control" id="message" cols="30" rows="5" required placeholder="Your Message"></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
