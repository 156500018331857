<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Web Site Development Services</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Web App Development Services</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">


                <div class="col-lg-5">
                    <div >
                        <img src="assets\images\C1.PNG.png" alt="Mobile-dev-image">
                    </div>
                </div>
                <!--  main paragraph Section -->
                <div class="col-lg-7">
                    <h2>Website Development Services</h2>
                    <p> The fast-paced website market is always changing. Our team specializes in mobile-first design and the delivery of cutting-edge hybrid or native apps to any app store.</p>
                    <p>The project will be managed by our specialist team of backend and frontend engineers as well as other professionals and the code is written by our skilled/certified team. Architecture, development and implementation are included in the RAD SUN IT SOLUTIONS PRIVATE LIMITED's top-notch Website development service.</p>
                    <div >
                        <span class="d-flex justify-content-start"><a routerlink="/faq" href="/faq">Please see our FAQ page.</a></span>
                        <div class="d-flex justify-content-end"><a routerlink="/contact" class="btn btn-primary" href="/contact">Contact Us</a></div>
                    </div>
                </div>

        </div>
        <div class="case">
            <ngx-tabset>

                <ngx-tab tabTitle="Project Execution">
                    <div class="row case-list">
                        <div class="col-lg-12 col-md-12">
                            <div class="single-case">
                                <h3>Our Methodology</h3>
                                <p> We use the Agile scrum technique, which is an incremental development project management system. Each iteration is divided into two to four-week sprints, with the goal of completing the most important features first and delivering a possibly deliverable product at the end of each sprint. </p>
                                <h3>SDLC's Phases (Software Development Life Cycle)</h3>
                                <p> Within a software organisation, the SDLC is a process that is followed for a software project. It is a detailed strategy that explains how to build, maintain, replace, and change or improve certain software. The life cycle is a mechanism for enhancing software quality and the development process as a whole. </p>

                            </div>
                            <section class="home-process-area pt-100 pb-70">
                                <div class="container">
                                    <div class="section-title">

                                        <h2>We are best because of our way of working</h2>

                                    </div>

                                    <div class="row">
                                        <div class="col-lg-3 col-sm-6">
                                            <div class="single-process">
                                                <div class="icon">
                                                    <img src="assets/images/process/p1.png" alt="process" />
                                                    <span><img src="assets/images/process/next.png" alt="shape" /></span>
                                                </div>

                                                <div class="content">
                                                    <h3>Research Product</h3>
                                                    <p>We do proper research before the begining of every project</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-sm-6">
                                            <div class="single-process">
                                                <div class="icon">
                                                    <img src="assets/images/process/p3.png" alt="process" />
                                                    <span><img src="assets/images/process/next.png" alt="shape" /></span>
                                                </div>

                                                <div class="content">
                                                    <h3>Development</h3>
                                                    <p>We take every care to deliver the best we can.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-3 col-sm-6">
                                            <div class="single-process">
                                                <div class="icon">
                                                    <img src="assets/images/process/p2.png" alt="process" />
                                                    <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                                                </div>

                                                <div class="content">
                                                    <h3>User Testing</h3>
                                                    <p>Properly curated user test cases to deliver best user experience.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-lg-3 col-sm-6">
                                            <div class="single-process">
                                                <div class="icon">
                                                    <img src="assets/images/process/p4.png" alt="process" />
                                                </div>

                                                <div class="content">
                                                    <h3>Product Handover</h3>
                                                    <p>Customer satisfaction is our prime goal and we are always there after the product handover also.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>

                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Technology Stack">
                    <div class="row case-list">
                        <h3>Our Technologies & Expertise</h3>
                        <p>Our achievements are made possible by the use of reliable, cutting-edge, and powerful technologies.</p>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-case">
                                <div class="content">
                                    <a ><h3>Languages</h3></a>

                                    <a class="line-bnt"> Javascript, Typescript, Python </a>
                                </div>
                                <div class="case-img">
                                    <a ><img src="assets/images/case/pyton.jpg" alt="case"/></a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-case">

                                <div class="content">
                                    <a><h3>Frameworks and libraries</h3></a>

                                    <a class="line-bnt"> ReactJS, Angular (SPA Frameworks) </a>
                                </div>
                                <div class="case-img">
                                    <a ><img src="assets/images/case/android.jpg" alt="case"/></a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-case">
                                <div class="content">
                                    <a><h3>Markup</h3></a>

                                    <a class="line-bnt"> Html, CSS/SCSS, Bootstrap, Material</a>
                                </div>
                                <div class="case-img">
                                    <a ><img src="assets/images/case/angular.jpg" alt="case"/></a>
                                </div>

                            </div>
                        </div>
                        <div class="col-lg-3 col-sm-6">
                            <div class="single-case">
                                <div class="content">
                                    <a ><h3>Cloud Host Provider</h3></a>

                                    <a class="line-bnt"> AWS Amazon, Google</a>
                                </div>
                                <div class="case-img">
                                    <a ><img src="assets/images/case/aws.jpg" alt="case"/></a>
                                </div>

                            </div>
                        </div>

                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Pricing">
                    <div class="row case-list">
                        <h3>Our Pricing Models</h3>
                        <p>We use a variety of pricing models, and our pricing range varies depending on the size and complexity of the project at hand.</p>
                        <p>Project deliverables vary from startups to medium/large sized new or existing businesses. For example, you may only want to validate a project idea, create a clickable prototype for investors, or add extensions to existing systems.</p>
                        <a routerlink="/pricing-details" href="/pricing-details">View our pricing models by clicking here.</a>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>

    </div>
</section>
