<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Blog Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="{{blogDetail.postImageUrl}}" alt="image" class="image_data">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li><span>Posted On:</span> <a routerLink="/blog">{{blogDetail.postDate | date}}</a></li>
                                <li><span>Posted By:</span> <a routerLink="/blog">{{blogDetail.postAuthor}}</a></li>
                            </ul>
                        </div>
                        <p [innerHtml]="postBodydata.postBody">{{postBodydata.postBody}}</p>
                        <blockquote class="flaticon-quote" *ngIf="postBodydata.postQuote">
                            <p>{{postBodydata.postQuote}}</p>
                        </blockquote>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class='bx bx-left-arrow-alt'></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class='bx bx-right-arrow-alt'></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">

                        <ol class="comment-list">
                            <li class="comment">
                                <div class="comment-body"  *ngFor="let i of blog_comments">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/images/blog-details/comment-img-1.jpg" class="avatar" alt="image">
                                            <b class="fn">{{i.commentAuthor.name}}</b>
                                        </div>

                                        <div class="comment-metadata">
                                            <a routerLink="/blog-details"><span>{{i.commentTime | date}}</span></a>
                                        </div>
                                    </footer>

                                    <div class="comment-content">
                                        <p>{{i.commentContent}}</p>
                                    </div>

                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </div>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form"  [formGroup]="blogForm">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked
                                    <span class="required">*</span>
                                </p>

                                <div class="comment-form-author">
                                    <label >Name <span class="required">*</span></label>
                                    <input (keyup)="nameinvalid()"  type="text" id="author" name="author" required="required" formControlName="name" class="form-control">
                                    <div class="alert alert-danger" *ngIf="nameinvalidError">
                                        <div>Full Name is required.</div>
                                    </div>
                                </div>

                                <div class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input (keyup)="emailinvalidError()" type="email" id="email" name="email" required="required" formControlName="email" class="form-control">
                                    <div class="alert alert-danger" *ngIf="emailinvalid">Valid email address is required.</div>
                                </div>

                                <div class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" name="url" formControlName="website" class="form-control">
                                </div>

                                <div class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" rows="5" maxlength="65525" required="required" formControlName="commentContent" class="form-control"></textarea>
                                </div>

                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment" (click)="submitData()">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
