<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Our official blog where we share our knowledge, insights, and opinions</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Blog</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
        </div>
        <div class="search_tab">
            <div class="form-outline mb-4">
                <input type="search" [(ngModel)]="searchText" class="form-control" id="datatable-search-input" placeholder="Search by blog title or keyword">
            </div>
        </div>
         <div>

         </div>
        <div class="row">
            <div class="col-lg-4 col-md-6"  *ngFor="let item of list | searchFilter: searchText  | paginate: { itemsPerPage: 6,currentPage: q }">
                <div class="single-blog" (click)="getData(item.id)">
                    <div class="blog-img">
                        <a><img src={{item.postImageUrl}} alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>{{item.postDate|date}}</li>
                            <li><a (click)="getData(item.id)">{{item.postAuthor}}</a></li>
                        </ul>
                        <a (click)="getData(item.id)"><h3>{{item.postTitle}}</h3></a>
                    </div>
                </div>
            </div>

            <pagination-controls (pageChange)="q = $event" class="pagine"></pagination-controls>

        </div>
    </div>
</section>
