<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="../../../../assets/images/radsun/logob.svg" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+918056149992"><i class="bx bxs-phone-call"></i> +91 8056149992</a></li>
                        <li><a href="mailto:contact@radsunitsolutions.com"><i class="bx bxs-envelope"></i>Contact@radsunitsolutions.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://www.linkedin.com/company/rad-sun-it-solutions-private-limited
" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="tel:+918056149992" target="_blank"><i class="bx bxs-phone-call"></i></a></li>
                        <li><a href="mailto:contact@radsunitsolutions.com" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow" style="background-color: #051242;">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="../../../../assets/images/radsun/logow.svg" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                        <li class="nav-item">
                            <a routerLink="">Home</a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/all-services">Services</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/all-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact:true}">Software Product  Development</a>
                                    <!-- <li class="nav-item"><a routerLink="/all-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact:true}">Ecommerce Development</a> -->
                                <li class="nav-item"><a routerLink="/technicalScreening" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Technical Screening </a></li>
                                <li class="nav-item"><a routerLink="/Web-dev" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Website Development</a></li>
                                <li class="nav-item"><a routerLink="/mobile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mobile App Development </a></li>
                                <li class="nav-item"><a routerLink="/dedicatedCrew" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dedicated Development Crew </a></li>
                                <li class="nav-item"><a routerLink="/all-services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact:true}">All services</a>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/solutions">Careers </a>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link dropdown-toggle" routerLink="/all-services">Technologies</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/tech-stack-expertise" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tech stack Expertise</a></li>
                                <li class="nav-item"><a routerLink="/technologies" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Team </a></li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/blog">Blog</a>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="/case-studies">Pricing</a>
                        </li>

                        <li class="nav-item">
                            <a routerLink="/about">About Us</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">FAQ </a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div class="nav-btn">
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </nav>
        </div>
    </div>
</div>
