import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'app-home-three',
    templateUrl: './home-three.component.html',
    styleUrls: ['./home-three.component.scss']
})
export class HomeThreeComponent implements OnInit {
    reactiveForm: FormGroup;
    nameinvalidError: boolean;
    emailinvalid: boolean;
    phoneinvalid: boolean;
    messageinvalid: boolean;
    list: any;

    constructor(private http: HttpClient,
        private fb: FormBuilder,
        private router: Router) { }

    ngOnInit(): void {
        this.reactiveForm = this.fb.group({
            name:['',Validators.required],
            email:['',Validators.required],
            phone:['',Validators.required],
            subject:[''],
            message:['',Validators.required]
        });

        this.http.get('https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/blog').subscribe(res =>{

            const data = res;
            this.list = data;
            this.list =this.list.content

            })
    }

    bannerImageSlides: OwlOptions = {
		loop: true,
		nav: true,
		dots: false,
		autoplayHoverPause: true,
		autoplay: true,
		autoplayTimeout: 2500,
		autoHeight: true,
		items: 1,
		animateOut: 'fadeOut',
        margin: 0,
        navText: [
            "<i class='flaticon-left-arrow'></i>",
            "<i class='flaticon-next-1'></i>"
        ],
    }
    teamSlides: OwlOptions = {
		loop: true,
        margin: 20,
        dots: true,
        autoplay: true,
        autoplayHoverPause: true,
        responsive: {
            0: {
                items: 1,
            },
            575: {
                items: 2,
            },
            576: {
                items: 2,
            },
            768: {
                items: 3,
            },
            992: {
                items: 3,
            },
            1200: {
                items: 4,
            }
        }
    }
    clientWrap: OwlOptions = {
		loop:true,
		margin:30,
		nav:false,
		mouseDrag: true,
		items:1,
		dots: false,
		autoHeight: true,
		autoplay: true,
		smartSpeed: 800,
		autoplayHoverPause: true,
		center: false,
		responsive:{
			0:{
				items:1,
				margin: 10,
			},
			576:{
				items:1,
			},
			768:{
				items:2,
				margin: 20,
			},
			992:{
				items:2,
			},
			1200:{
				items:2,
			}
		}
    }


    nameinvalid() {
        if(this.reactiveForm.get('name').value == "" || this.reactiveForm.get('name').value == undefined){
                this.nameinvalidError = true;
            } else {
                this.nameinvalidError = false;
            }
        }

        emailinvalidError() {
            if(this.reactiveForm.get('email').value == "" || this.reactiveForm.get('email').value == undefined){
                this.emailinvalid = true;
            } else {
                this.emailinvalid = false;
            }
        }

        phoneinvalidError() {
            if(this.reactiveForm.get('phone').value == "" || this.reactiveForm.get('phone').value == undefined){
                this.phoneinvalid = true;
            } else {
                this.phoneinvalid = false;
            }
        }

        messageinvalidError() {
            if(this.reactiveForm.get('message').value == "" || this.reactiveForm.get('message').value == undefined){
                this.messageinvalid = true;
            } else {
                this.messageinvalid = false;
            }
        }

        submitData(){
            console.log(this.reactiveForm.value);
            if(this.reactiveForm.invalid) {
                this.nameinvalid();
                this.emailinvalidError();
                this.messageinvalidError();
                this.phoneinvalidError();
            } else {
              const contactusdata = this.http.post('https://cybtrqh7y9.execute-api.eu-west-2.amazonaws.com/dev/dynamodb',this.reactiveForm.value)
              const emailVerification = this.http.post('https://cybtrqh7y9.execute-api.eu-west-2.amazonaws.com/dev/mailgun',this.reactiveForm.value)

              forkJoin([contactusdata,emailVerification]).subscribe(res =>{
                  return res;
              })
              this.reactiveForm.reset();
            }
        }

}
