<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Contact Us</li>
            </ul>
        </div>
    </div>

    
</div>

<section class="contact-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-envelope'></i>
                    </div>

                    <div class="content">
                        <h3>Email</h3>
                        <p>contact@radsunitsolutions.com</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-location-plus'></i>
                    </div>

                    <div class="content">
                        <h3>Address</h3>
                        <p> 26, Nowroji Street, Chetpet, Chennai, Tamilnadu, 600031</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-12">
                <div class="single-contact">
                    <div class="icon">
                        <i class='bx bx-phone-call'></i>
                    </div>

                    <div class="content">
                        <h3>Phone</h3>
                        <p>+91 8056149992</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span> </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" [formGroup]="reactiveForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input formControlName="name" (keyup)="nameinvalid()" type="text" name="name" id="name" class="form-control" required placeholder="Your Name*" />
                                    <div class="alert alert-danger" *ngIf="nameinvalidError">
                                        <div>Your Name is required.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" formControlName="email" (keyup)="emailinvalidError()" name="email" id="email" class="form-control" required placeholder="Your Email*" />
                                    <div class="alert alert-danger" *ngIf="emailinvalid">Your Email Address is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="number"  (keyup)="phoneinvalidError()" formControlName="phone" name="phone" id="phone" required class="form-control" placeholder="Your Phone*" />
                                    <div class="alert alert-danger" *ngIf="phoneinvalid">Your Phone Number is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="subject" id="subject" formControlName="subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" (keyup)="messageinvalidError()" class="form-control" id="message" formControlName="message" cols="30" rows="5" required placeholder="Your Message *"></textarea>
                                    <div class="alert alert-danger" *ngIf="messageinvalid">Your Message is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" (click)="submitData()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.png" alt="contact " />
                </div>
            </div>
        </div>
    </div>
</section>
