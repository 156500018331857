import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-tech',
  templateUrl: './team-tech.component.html',
  styleUrls: ['./team-tech.component.scss']
})
export class TeamTechComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
