<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>

    
</div>

<section class="home-case pt-100 pb-70">
    <div class="container">
        <div class="case">
            <div class="row case-list">
                <div class="col-lg-6 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a><h3>Model of Fixed Cost Pricing</h3></a>
                            <p>A fixed cost pricing model is one that ensures a fixed budget for the project regardless of time or expense.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a><h3>Model of Time and Materials</h3></a>
                            <p>This is a model of engagement in which the client only pays for the time and resources spent on the project.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a><h3>Model of Milestone Pricing</h3></a>
                            <p>The milestone pricing model charges based on agreed-upon deliverables.</p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-6">
                    <div class="single-case">
                        <div class="content">
                            <a><h3>Popular Hybrid Model</h3></a>
                            <p>A mix of Fixed, Milestone and T & M models. They varies with scale/duration and complexity of project.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
