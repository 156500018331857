<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Dedicated Development Crew</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Dedicated Development Crew</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>
<section class="home-service-area pt-100 pb-70">
    <div class="container">

        <div class="row align-items-center">


            <div class="col-lg-4">
                <div >
                    <img src="assets\images\ddc.png" alt="Mobile-dev-image">
                </div>
            </div>
            <!--  main paragraph Section -->
            <div class="col-lg-8">
                <h2>Achieve Development services from a dedicated team</h2>
                <br>

                <p>Contractual engagement approaches with the ability to adapt software outsourcing based on company demands and project scope ensure improved efficiency and top-notch resources are available to our customers.</p>
                 <p>Our extensive range of services covers a wide number of industries, allowing us to provide continuous project support with high-end software solutions.</p>

            </div>

    </div>

        <div class="row" style="margin: top 60px;">
            <div class="col-lg-6 col-sm-6">
                <a routerLink="/solutions-details">
                    <div class="single-service">

                        <img src="assets/images/service/sa.png" alt="service" />


                    <div class="service-content">
                        <h3>IT Staff Augmentation Service</h3>

                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
                </a>

            </div>

            <div class="col-lg-6 col-sm-6">
                <a routerLink="/solutions-details">
                    <div class="single-service">

                        <img src="assets/images/service/hd.png" alt="service" />


                    <div class="service-content">
                        <h3>Dedicated Development Team Hiring Service</h3>

                        <a routerLink="/solutions-details" class="line-bnt">Read More</a>
                    </div>
                </div>
                </a>

            </div>

         </div>
    </div>
</section>
