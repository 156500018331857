import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.scss']
})
export class BlogDetailsComponent implements OnInit {
  blogDetail: any = [];
  blogForm:FormGroup;
    list: Object;
    blog_comments: any;
    nameinvalidError: boolean;
    emailinvalid: boolean;
    pid: any;
    blogDetailer: any;
    postBodydata: any =[];

  constructor(private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { }

  ngOnInit(): void {

    this.blogForm = this.fb.group({
        name:['',Validators.required],
        email:['',Validators.required],
        website:[''],
        commentContent:['']
    });


    this.activatedRoute.queryParams.subscribe(params => {
        this.pid = params['id'];
         let queryParams = new HttpParams();
        queryParams = queryParams.append("id",this.pid);

    this.http.get('https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/blog/post', {params:queryParams} ).subscribe(res=>{

        const blogData = res;
        this.blogDetailer = blogData;
       this.blog_comments = this.blogDetailer.blog_comments;
       this.blogDetail = this.blogDetailer.blog_details;
       this.postBodydata = this.blogDetail.postContent;

        console.log()
        return res;
    })
    });


  }

  nameinvalid() {
    if(this.blogForm.get('name').value == "" || this.blogForm.get('name').value == undefined){
            this.nameinvalidError = true;
        } else {
            this.nameinvalidError = false;
        }
    }

    emailinvalidError() {
        if(this.blogForm.get('email').value == "" || this.blogForm.get('email').value == undefined){
            this.emailinvalid = true;
        } else {
            this.emailinvalid = false;
        }
    }

    submitData() {
        console.log(this.blogForm.value);
        if(this.blogForm.invalid) {
            this.nameinvalid();
            this.emailinvalidError();
        } else {
            const data = {
                "postId":this.pid,
                "commentContent":this.blogForm.get('commentContent').value,
                "commentAuthor":  {
                    "name":this.blogForm.get('name').value,
                    "email": this.blogForm.get('email').value,
                    "website": this.blogForm.get('website').value,
                }
           }
            this.http.post(' https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/blog/post/comment',data).subscribe(res =>{
                this.ngOnInit();
              return res;
          })
          this.blogForm.reset();
        }
    }

}
