<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Technical Screening Services</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Technical Screening Services</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <!-- <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>

        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div> -->
    </div>
</div>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">


            <div class="col-lg-5">
                <div >
                    <img src="assets\images\TS.png" alt="Technical Screening">
                </div>
            </div>
            <!--  main paragraph Section -->
            <div class="col-lg-7">
                <h2>Technical Screening Through Smart Approach</h2>
                <p>Successful tech recruitment depends on technical screening.
                To achieve your quality of hire goals, it is critical to identify and connect with highly qualified candidates's.
                RAD SUN IT SOLUTIONS PRIVATE LIMITED's technical screening process allows you to identify and connect with high-potential candidates's.
                Its important to hire people who are smart, that get stuff done and have the functional set of skills you need for the role.
                Keeping our mission, vision and values in mind, we have developed a staged screening process to help tech interviewers make better hiring decisions.</p>

            </div>

        </div>

        <div class="row" style="margin-top: 60px;">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">


                    <div class="service-content">
                        <h3>Preliminary Stage</h3>
                        <p>In this round of the hiring process, candidates's' overall experience (education, career trajectory, certifications, recent experience, etc.) and suitability for the role is evaluated.  If a candidate doesn’t show sufficient knowledge during the interview, we won’t recommend them for the next round of the hiring process.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">


                    <div class="service-content">
                        <h3>Deep Technical Stage</h3>
                        <p>During this round, candidates's are tested on their technical proficiency, problem solving abilities, and ability to meet mentioned role requirements. Interviews are conducted either via phone, video, online coding tests, or face-to-face.</p>

                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">


                    <div class="service-content">
                        <h3>Final Report</h3>
                        <p>Our team assess the candidates's's suitability to role and prepares the report based on outcome from Preliminary and Technical rounds. </p>

                    </div>
                </div>
            </div>
         </div>
    </div>
</section>
