<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="../../../../assets/images/radsun/logow.svg" alt="logo" /></a>
                    </div>

                    <ul class="social" style="padding-top:10px;">
                        <li><a href="https://www.linkedin.com/company/rad-sun-it-solutions-private-limited
" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="tel:+918056149992" target="_blank"><i class="bx bxs-phone-call"></i></a></li>
                        <li><a href="mailto:contact@radsunitsolutions.com" target="_blank"> <i class="bx bxs-envelope"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="content">
                    <h3>Quick Links</h3>

                    <ul class="footer-list">
                        <li><a routerLink="/all-services">Services</a></li>
                        <li><a routerLink="/tech-stack-expertise">Technologies</a></li>
                        <li><a routerLink="/solutions">Careers</a></li>
                        <li><a routerLink="/cookie-policy">Cookie Policy</a></li>
                        <li><a routerLink="/privacy-policy">Privacy & Policy</a></li>
                        <li><a routerLink="/terms-condition">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social" >
                        <li><a href="tel:+918056149992"><i class="bx bxs-phone-call"></i> +91 8056149992</a></li>
                        <li><a href="mailto:contact@radsunitsolutions.com"><i class="bx bxs-envelope"></i> contact@radsunitsolutions.com</a></li>
                        <li><a href="https://maps.app.goo.gl/GKY6FB7TQEzPz3RL6"><i class="bx bxs-map"></i>  26, Nowroji Street, Chetpet, Chennai, Tamilnadu, 600031</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="">Home</a></li>
                        <!-- <li><a routerLink="/solutions">Services</a></li> -->
                        <li><a routerLink="/solutions">Careers</a></li>
                        <!-- <li><a routerLink="/blog">Blog</a></li> -->
                        <li><a routerLink="/about">About Us</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p class="right">Copyright © All Rights Reserved by <a href="https://www.radsunitsolutions.com/" target="_blank">RAD SUN IT SOLUTIONS PRIVATE LIMITED</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>
