<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>All Services</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">All Services</li>
            </ul>
        </div>
    </div>

    
</div>

<section class="feature-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <h2>Our Featured Services That We Provide</h2>
                        <p>We create great digital experiences from the stage of verifying your business, use case ideas with market research and completing Requirements Analysis to the stage of Product Delivery and Maintenance.</p>
                    </div>
                    <ul >
                        <li><i class="flaticon-correct"></i> Market Research Assistance </li>
                        <li><i class="flaticon-correct"></i>  UI/UX Design </li>
                        <li><i class="flaticon-correct"></i>  Clickable Prototype </li>
                        <li><i class="flaticon-correct"></i>  UI Components  </li>
                        <li><i class="flaticon-correct"></i>  Web Development </li>
                        <li><i class="flaticon-correct"></i> Mobile App Development  </li>
                        <li><i class="flaticon-correct"></i> AI/ML Modules Development </li>
                        <li><i class="flaticon-correct"></i>  Code Review Service</li>
                        <li><i class="flaticon-correct"></i>  API Management  </li>
                        <li><i class="flaticon-correct"></i>  Migrate Legacy Apps </li>
                        <li><i class="flaticon-correct"></i>   E-commerce Development  </li>
                        <li><i class="flaticon-correct"></i>  Review &amp; Automate Back Office Systems </li>
                        <li><i class="flaticon-correct"></i>  Software Testing  </li>
                        <li><i class="flaticon-correct"></i>  Support &amp; Maintenance  </li>
                        <li><i class="flaticon-correct"></i>  Digital Marketing</li>
                        <li><i class="flaticon-correct"></i> Team Extensions</li>
                        <li><i class="flaticon-correct "></i> Technical Screening </li>
                        <li><i class="flaticon-correct"></i> Website Review Service</li>
                        <li><i class="flaticon-correct"></i> Retrain or upskill your team </li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div  class="col-lg-6 col-md-12 services-right-image">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>
