import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss']
})
export class SolutionsComponent implements OnInit {

    list: any;
    carrerDetail: Object;
    listData: any;
    searchText: string;
    pageOfItems: Array<any>;
    q:any;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
      this.http.get(`https://xhad6w0hz8.execute-api.ap-south-1.amazonaws.com/prod/careers`).subscribe(res=>{
        const list =res;
        this.carrerDetail = list;        // this.blog_comments = this.blogDetail.blog_comments;
        this.list = this.carrerDetail;
        this.listData = this.list.content      })
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
}

}
