<div class="main-banner-three">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="main-banner-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="content">
                                <h2>We offer full-stack software development services, from the MVP phase to the successful launch.</h2>
                                <p>“In the end, it all comes down to 0 and 1”</p>
                                <p>― Vineet Goel</p>
                                <div class="slider-btn-wrap">
                                    <a routerLink="/contact" class="box-btn">Contact Us </a>
                                    <a routerLink="/all-services" class="box-btn border-btn">Our Services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 pr-0 col-md-12">
                <div class="banner-image-slider">
                    <owl-carousel-o [options]="bannerImageSlides">
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-1"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-3"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-4"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-5"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-6"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-7"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-8"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-9"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-10"></div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="banner-image banner-slider-bg-11"></div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-company-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Why Us?</h2>
                        <p>We are enhancing operational firm that specialises in technology solutions. </p>
                        <p>We collaborate with small and medium-sized enterprises, using cutting-edge technology to guide them through all stages of the software development cycle.</p>
                        <p>We create customized web/mobile applications to solve business problems, promote development, and help you reach your company objectives.</p>
                        <a href="/all-services">Please see our All Services page.</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="assets/images/1.png" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>


<div class="info-area pt-100 pb-70 bg-color">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i1.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-info"></i>Our Services</h3>
                        <div class="arrow">
                            <a routerLink="/all-services"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-info">
                    <div class="info-img">
                        <img src="assets/images/info/i2.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-support"></i>Technologies</h3>
                        <div class="arrow">
                            <a routerLink="/tech-stack-expertise"> <i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-info si-30">
                    <div class="info-img">
                        <img src="assets/images/info/i3.jpg" alt="info" />
                    </div>

                    <div class="content">
                        <h3><i class="flaticon-goal"></i> About Us</h3>
                        <div class="arrow">
                            <a routerLink="/about"><i class="flaticon-next-1"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Smart Services</span>
            <h2>Our Featured Services that We Offer are as follows:</h2>
            <p>Starting with market research and requirements analysis to verify your business use case ideas, we design amazing digital experiences all the way to product delivery and maintenance.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service" routerLink="/Web-dev">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Website Development</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service" routerLink="/mobile">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Mobile App Development</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service"  routerLink="/dedicatedCrew">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Team Extensions</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service" routerLink="/all-services">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3> Code Review Services</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service" routerLink="/technicalScreening">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Technical Screening</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service" routerLink="/all-services">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Digital Marketing</h3>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img1.png" alt="choose"/>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Technological Advances and Expertise</h2>
                    <p>At RAD SUN IT SOLUTIONS PRIVATE LIMITED, our technical professionals support businesses in successfully completing their digital transformation journeys and effortlessly providing robust, scalable apps of any complexity to their clients.</p>
                    <p>We collaborate with start-ups and medium-sized businesses, utilising cutting-edge technical capabilities to guide them through all phases of their software development cycle.</p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2></div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Research Product</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>User Testing</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next2.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Development</h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>Product Handover</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home2-choose-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>RAD SUN IT SOLUTIONS PRIVATE LIMITED</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2> </div>

        <div class="row">
            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-friends"></i>
                    </div>
                    <h3>Understanding</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-award"></i>
                    </div>
                    <h3>Best Quality</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-chip"></i>
                    </div>
                    <h3>Technology</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-customer-service"></i>
                    </div>
                    <h3>Support</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-like"></i>
                    </div>
                    <h3>Expert Team</h3>
                </div>
            </div>

            <div class="col-lg-2 col-sm-4">
                <div class="single-choose">
                    <div class="icon">
                        <i class="flaticon-coin"></i>
                    </div>
                    <h3>Price Oriented</h3>
                </div>
            </div>
        </div>
    </div>
</section>


<!-- <section class="home-blog-area pt-100 pb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2></div>

        <div class="row">
            <div class="col-lg-4 col-md-6"  *ngFor="let item of list | slice:0:3;">
                <div class="single-blog">
                    <div class="blog-img">
                        <a><img src={{item.postImageUrl}} alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>{{item.postDate|date}}</li>
                            <li><a routerLink="/blog">{{item.postAuthor}}</a></li>
                        </ul>
                        <a routerLink="/blog-details"><h3>{{item.postTitle}}</h3></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section> -->

<section class="home-contact-area home-2-contact pb-100">
    <div class="container">
        <div class="section-title mt-5">
            <h2>Contact Us</h2> </div>

        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm" [formGroup]="reactiveForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input formControlName="name" (keyup)="nameinvalid()" type="text" name="name" id="name" class="form-control" required placeholder="Your Name*" />
                                    <div class="alert alert-danger" *ngIf="nameinvalidError">
                                        <div>Your Name is required.</div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" formControlName="email" (keyup)="emailinvalidError()" name="email" id="email" class="form-control" required placeholder="Your Email*" />
                                    <div class="alert alert-danger" *ngIf="emailinvalid">Your Email Address is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="number"  (keyup)="phoneinvalidError()" formControlName="phone" name="phone" id="phone" required class="form-control" placeholder="Your Phone*" />
                                    <div class="alert alert-danger" *ngIf="phoneinvalid">Your Phone Number is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="subject" id="subject" formControlName="subject" class="form-control" required placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" (keyup)="messageinvalidError()" class="form-control" id="message" formControlName="message" cols="30" rows="5" required placeholder="Your Message *"></textarea>
                                    <div class="alert alert-danger" *ngIf="messageinvalid">Your Message is required.</div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn" (click)="submitData()">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="contact-img contact-img-2">
                    <img src="assets/images/contact-img2.png" alt="contact" />
                </div>
            </div>
        </div>
    </div>
</section>
